<template>
  <div
    class="header"
    :style="{ backgroundImage: 'url(' + this.category_background + ')' }"
  >
    <div class="title">{{ this.category_name }}</div>
  </div>
  <List
    :data="product_data"
    :count="product_count"
    :category_id="category_id"
    :data_fully_loaded="data_fully_loaded"
    @load_more="this.load_product_data"
    @change_sort="this.change_sort"
    @change_filter="this.change_filter"
  ></List>
  <Spinner v-show="processing" />
</template>

<script>
import List from "@/views/product/List.vue";
export default {
  name: "Category",
  components: {
    List,
  },
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.category_name,
      og: {
        title: this.$t("meta.title") + " :: " + this.category_name,
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      category_id: 0,
      category_name: "",
      category_background: "",
      product_data: [],
      product_count: 0,

      data_fully_loaded: false,
      search_page: 1,
      search_sorting: {},
      search_filter: {},
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.category_id = parseInt(this.$route.params.category_id);
    this.load_category_data();
    this.load_product_data();
  },
  methods: {
    // 카테고리 정보 가져오기
    load_category_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/category/get",
          {
            category_id: self.category_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;
          if (data.result == "OK") {
            self.category_name = data.data.category_name;

            if (
              typeof data.data.category_background != "undefined" &&
              data.data.category_background != null &&
              data.data.category_background != ""
            ) {
              self.category_background =
                process.env.VUE_APP_API_URL +
                "/resource/get/category/" +
                self.category_id +
                "/" +
                data.data.category_background;
            }
          }

          return true;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //!-- axios
    }, //-- load_category_data

    // 카테고리 상품 가져오기
    load_product_data: function () {
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/list",
          {
            category_id: self.category_id,
            filter: self.search_filter,
            sorting: self.search_sorting,
            page: self.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (self.search_page <= 1) {
            self.product_data = [];
          }

          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            self.product_data.push(data[i]);
          }

          if (data.length <= 0) {
            self.data_fully_loaded = true;
          } else {
            self.search_page++;
          }

          // 총 갯수 정보 헤더에서 가져오기
          try {
            let str_info = response.headers.wkt_extra_info;
            let extra_info = JSON.parse(str_info);
            self.product_count = parseInt(extra_info.total_count);
          } catch (e) {
            console.log(e);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //!-- axios
    }, //-- load_product_data

    // 정렬 변경 이벤트 핸들러
    change_sort: function (sorting) {
      this.search_sorting = sorting;

      this.data_fully_loaded = false;
      this.search_page = 1;

      this.load_product_data();
    },

    // 필터 변경 이벤트 핸들러
    change_filter: function (filter) {
      this.search_filter = filter;

      this.data_fully_loaded = false;
      this.search_page = 1;

      this.load_product_data();
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
  text-align: center;
  height: 300px;

  background-color: #808080;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.header > .title {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);

  font-size: 2rem;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.3rem;

  /* text-shadow: 0 0 2px rgba(255, 255, 255, 0.85); */
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.45);
}
</style>
